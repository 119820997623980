const isPageIndicationText = (text: string) => /^[^\d<]*\d+[^\d]+\d+[^\d>]*/.exec(text)

const verifyAndFixPageIndicationElement = (appPart2: HTMLDivElement) => {
	const pageIndicationFromScraper = appPart2.querySelector<HTMLElement>('[data-pagination-page-indication]')!
	if (isPageIndicationText(pageIndicationFromScraper.innerHTML)) {
		// all good
		return
	}

	const paginationControls = appPart2.querySelector('[data-pagination-controls]')
	const detectedPageIndication =
		paginationControls &&
		Array.from(paginationControls.querySelectorAll<HTMLElement>('*')).find((elm) =>
			isPageIndicationText(elm.innerHTML)
		)

	if (!detectedPageIndication) {
		// pagination will work, but the indication won't look as expected
		// TODO - report error
		return
	}

	delete pageIndicationFromScraper.dataset.paginationPageIndication
	pageIndicationFromScraper.removeAttribute('data-pagination-page-indication')

	detectedPageIndication.dataset.paginationPageIndication = 'true'
}

const getPaginationItemsPerPage = (appPart2: HTMLDivElement) =>
	parseInt(
		appPart2.querySelector<HTMLDivElement>('[data-pagination-items-per-page]')?.dataset.paginationItemsPerPage ||
			'0',
		10
	)

const getPaginationControls = (appPart2: HTMLDivElement) => ({
	prevPageButton: appPart2.querySelector<HTMLElement>('[data-pagination-prev]')!,
	nextPageButton: appPart2.querySelector<HTMLElement>('[data-pagination-next]')!,
	pageIndication: appPart2.querySelector<HTMLElement>('[data-pagination-page-indication]')!,
})

const getItems = (appPart2: HTMLDivElement) =>
	Array.from(appPart2.querySelector('[data-pagination-items-wrapper]')!.children) as Array<HTMLElement>

export const initPaginatedList = (appPart2: HTMLDivElement) => {
	const paginationItemsInPage = getPaginationItemsPerPage(appPart2)
	if (!paginationItemsInPage) {
		return
	}

	verifyAndFixPageIndicationElement(appPart2)

	const { prevPageButton, nextPageButton, pageIndication } = getPaginationControls(appPart2)
	const items = getItems(appPart2)
	const numberOfPages = Math.ceil(items.length / paginationItemsInPage)

	const setPageItemsDisplayTo = (display: 'none' | 'block') => (pageIndex: number) => {
		items.slice(pageIndex * paginationItemsInPage, (pageIndex + 1) * paginationItemsInPage).forEach((item) => {
			item.style.display = display
		})
	}
	const hideItemsInPage = setPageItemsDisplayTo('none')
	const showItemsInPage = setPageItemsDisplayTo('block')

	const setPageIndication = (pageNumber: number) => {
		pageIndication.innerHTML = pageIndication.innerHTML.replace(
			/^([^\d]*)(\d+)([^\d]+)(\d+)([^\d]*)/, // (padding?)(currentPage)(separator)(totalPages)(padding?)
			`$1${pageNumber}$3${numberOfPages}$5`
		)
	}

	let currentPageIndex = 0
	setPageIndication(1)

	const switchToPage = (nextPageIndex: number) => {
		hideItemsInPage(currentPageIndex)
		showItemsInPage(nextPageIndex)
		setPageIndication(nextPageIndex + 1)
		currentPageIndex = nextPageIndex
	}

	prevPageButton.addEventListener('click', () => {
		switchToPage(currentPageIndex <= 0 ? numberOfPages - 1 : currentPageIndex - 1)
	})

	nextPageButton.addEventListener('click', () => {
		switchToPage(currentPageIndex >= numberOfPages - 1 ? 0 : currentPageIndex + 1)
	})
}
