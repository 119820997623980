import React, { ComponentType, useRef } from 'react'
import { appPart2 } from './AppPart2.scss'
import { initPaginatedList } from './paginatedList'

type AppPart2CompProps = {
	__html: string
	id: string
}

const AppPart2: ComponentType<AppPart2CompProps> = ({ id, __html }) => {
	const ref = useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		if (__html) {
			if (ref.current!.innerHTML === '') {
				// in case there was an error in SSR
				ref.current!.innerHTML = __html
			}

			initPaginatedList(ref.current!)
		}
	}, [__html])

	return <div id={id} ref={ref} className={appPart2} dangerouslySetInnerHTML={{ __html }} />
}

export default AppPart2
